<template>
  <div data-app>
    <v-container >
      <v-row>
        <v-col cols="2" class="pt-5">
          <v-select
            v-model="searchOption"
            :items="searchOptions"
            item-text="text"
            item-value="id"
            label="giáo viên"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6" class="pt-7">
          <HnrInput
            :name="'title'"
            v-model="key_search"
            :text="key_search"
            :label="'Nhập title slider'"
          ></HnrInput>
        </v-col>
        <v-col cols="1">
          <HnrButton
            :width="100"
            @hnrClickEvent="getSearchResult"
            :text="buttonText"
            :color="buttonColor"
            :height="40"
            :outlined="true"
          ></HnrButton>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-data-table
        :headers="headers"
        :items="students"
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-card-title>
            {{ lang.listStudents }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.affiliateCode="{ item }">
        <v-btn
            v-if="!item.code_number"
            class="mx-2"
            dark
            color="teal"
            width="120"
            height="25"
            v-on:click="createAffiliate(item.id)"
        >
          {{ lang.createAffiliate }}
        </v-btn>
        <v-btn
            v-else v-on:click="showListAffiliateCodeUsed(item.id, item.code_number)"
            class="mx-2"
            dark
            color="black"
            width="120"
            height="25"

        >
          {{ item.code_number }}
        </v-btn>
      </template>
      <template v-slot:item.role_user="{ item }">
        <v-checkbox
            v-model="item.is_teacher"
            color="success"
            v-on:click="updateUser(item.id, item.is_teacher)"
            :value="item.is_teacher"
            hide-details
            class="mb-6"
        ></v-checkbox>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status" class="green--text">Đã kích hoạt</span>
        <span v-if="!item.status" class="red--text">Chưa kích hoạt</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="getDetail(item.id, item.is_teacher)"
        >
          mdi-eye
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item, item.id)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item, item.id)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          Your search for "{{ searchAlertMess }}" found no results.
        </v-alert>
      </template>
    </v-data-table>
    <updateUserInfo :dialog="dialog" :id="user_id"></updateUserInfo>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  DELETE_USER,
  GET_USER_INFO,
  GET_LIST_STUDENT,
  COUNT_STUDENT,
  UPDATE_USER_TYPE,
  GET_STUDENT_SEARCH_RESULT,
} from "@/store/user.module";
import HnrButton from "@/components/elements/hnr-button";
import HnrInput from "@/components/elements/hnr-input";
import { validationMixin } from "vuelidate";
import updateUserInfo from "@/components/user/updateUserInfo";

export default {
  mixins: [validationMixin],
  components: {
    HnrButton,
    HnrInput,
    updateUserInfo
  },
  data: () => ({
    lang: {
      listStudents: "Danh sách học viên",
      listAffiliate: "Danh sách người đã sử dụng",
      createAffiliate: "Tạo mã giới thiệu"
    },
    buttonText: "Tìm kiếm",
    buttonColor: "primary",
    searchOptions: [
      { text: "Giáo viên", id: 1 },
      { text: "Học viên", id: 0 }
    ],
    searchOption: 1,
    dialog: false,
    searchAlertMess: "Giáo viên",
    page: 1,
    itemsPerPage: 10,
    key_search: "",
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Tên", value: "full_name" },
      { text: "Email", value: "email" },
      { text: "Số điện thoại", value: "phone_number" },
      { text: "Trạng thái", value: "status" },
      { text: "Giáo viên", value: "role_user", sortable: false },
      { text: "Hành động", value: "actions", sortable: false },
      { text: "Mã giới thiệu", value: "affiliateCode", sortable: false , width:'70px' }
    ],
    user_id: "",
    image: {},
    editedIndex: -1,
    editedItem: {
      id: "",
      title: "loremislum",
      content: "heyasfasfa",
      author: "jobert",
      created_date: "2020-09-08"
    },
    defaultItem: {
      id: "",
      title: "loremislum",
      content: "heyasfasfa",
      author: "jobert",
      created_date: "2020-09-08"
    },
    url: null
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    students() {
      return this.$store.getters.listStudents;
    },
    total() {
      return this.$store.getters.studentCount;
    },
    submitTypeName() {
      return this.editedIndex === -1 ? "Save" : "Update";
    }
  },
  mounted() {
    this.count();
    this.getStudents();
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created () {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Học viên",titledad:'Người dùng' }]);
  },

  methods: {
    getStudents() {
      var payload = { page: this.page, page_count: this.itemsPerPage };
      this.$store.dispatch(GET_LIST_STUDENT, payload);
    },
    count() {
      this.$store.dispatch(COUNT_STUDENT);
    },

    async editItem(item, user_id) {
      await this.$store.dispatch(GET_USER_INFO, { id: user_id });
      this.dialog = !this.dialog;
      this.editedIndex = this.students.indexOf(item);
      this.user_id = user_id;
      var payload = { page: this.page, page_count: this.itemsPerPage };
      await this.$store.dispatch(GET_LIST_STUDENT, payload);
    },
    async deleteItem(item, user_id) {
      if (confirm("are you sure?")) {
        await this.$store.dispatch(DELETE_USER, { id: user_id });
        this.editedIndex = this.students.indexOf(item);
        this.students.splice(this.editedIndex, 1);
      }
    },
    getSearchResult() {
      var payload = { type: this.searchOption, key_search: this.key_search };
      this.$store.dispatch(GET_STUDENT_SEARCH_RESULT, payload);
    },
    updateUser(id, is_teacher) {
      if (is_teacher === null) {
        is_teacher = false;
      }
      this.$store.dispatch(UPDATE_USER_TYPE, {
        id: id,
        is_teacher: is_teacher
      });
    },
    getDetail(user_id, is_teacher) {
      if (is_teacher) {
        this.$router.push({
          path: "/user/teacher/" + user_id + "/info"
        });
      } else {
        this.$router.push({
          path: "/user/student/" + user_id + "/info"
        });
      }
    },
    showListAffiliateCodeUsed(user_id, code) {
      this.$router.push({
        path: "/afflificate/detail",
        query: {
          affiliate_code: code,
          user_id: user_id
        }
      });
    },
    async createAffiliate(user_id) {
      // await this.$store.dispatch(CREATE_AFFILIATE_CODE, {
      //   user_id: user_id
      // });
      await this.$router.push({
        path: "/afflificate/create-code",
        query: {
          user_id: user_id,
        }
      });
      var payload = { page: this.page, page_count: this.itemsPerPage };
      await this.$store.dispatch(GET_LIST_STUDENT, payload);
    }
  }
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
